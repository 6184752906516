import React from "react";
import { useNavigate } from "react-router-dom";


const SideMenu = ({
  selectedFunctionality,
  userCount,
  documentCount,
}) => {
 
  const navigate = useNavigate()
  const handleLogout = async () => {
    sessionStorage.removeItem('admin')
    navigate('/admin')
  };
  return (
    <div className="sidebarList justify-content-between col-md-2 bg-dark@ text-light p-4 d-flex flex-column vh-100 posi tion-fixed">
      <div
        className={`menu-item ${
          selectedFunctionality === "users" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin")}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center">
          <i className="fa fa-users me-2" aria-hidden="true"></i>
          <span>All Users</span>
        </div>
        {userCount !== undefined && (
          <span className="badge badge-primary">{userCount}</span>
        )}
      </div>
      <div
        className={`menu-item ${
          selectedFunctionality === "add User" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/add-user")}
        style={{ cursor: "pointer" }}
      >
         <div className="d-flex align-items-center">
          <i className="fa fa-user-plus me-2"></i>
          <span>Add User</span>
        </div>
      </div>
      <div
        className={`menu-item ${
          selectedFunctionality === "documents" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/documents")}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center">
          <i className="fa fa-file me-2" aria-hidden="true"></i>
            <span>Documents</span>
        </div>
        {documentCount !== undefined && (
          <span className="badge badge-primary">{documentCount}</span>
        )}
      </div>
      <div
        className={`menu-item ${
          selectedFunctionality === "upload template" ? "active" : ""
        }  `}
        onClick={() => navigate("/admin/upload-documents")}
        style={{ cursor: "pointer" }}
      >
         <div className="d-flex align-items-center">
            <i className="fa fa-file me-2" aria-hidden="true"></i>
            <span>Upload Documents</span>
          </div>
        
      </div>

      <div
        className="mt-auto mb-3 ms-5"
        onClick={handleLogout}
        style={{ cursor: "pointer" }}
      >
        <i className="fas fa-sign-out-alt"></i> Logout
      
      </div>
    </div>
  );
};

export default SideMenu;
