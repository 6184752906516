import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';

const FormTwo = (({ data, setData }) => {
  const selectedConstruction = sessionStorage.getItem("selectedConstruction");

  const [noteEdit, setNoteEdit] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const handleClose = () => setShowNotes(false);

  const handleSave = () => {
    noteEdit.item.notes[noteEdit.column] = noteEdit.text;
    setData(data);

    const updatedData = data.map((section) => {
      const updatedSubSections = section.sub?.map((subSection) => {
        const updatedItems = subSection.data?.map((item) => {
          if (item.id === noteEdit.item.id) {
            const updatedItem = { ...item, notes: { ...noteEdit.item.notes, [noteEdit.column]: noteEdit.text } };
            return updatedItem;
          }
          return item;
        });
        return { ...subSection, data: updatedItems };
      });
      return { ...section, sub: updatedSubSections };
    });

    // Update the state with the new data
    setData(updatedData);
    sessionStorage.setItem("steptwoFormData", JSON.stringify(updatedData));

    handleClose();
  };
  const handleShow = (col, tr) => (e) => {
    setNoteEdit({
      column: col,
      item: tr,
      text: tr.notes[col]
    })
    setShowNotes(true);
  };

  const columns = [
    "COMPLIES",
    "DOES NOT COMPLY",
    "DESIGN DETAIL",
    "DESIGN CERTIFICATION",
    "NOT APPLICABLE",
    "PERFORMANCE SOLUTION",
    "CAPABLE OF COMPLIANCE",
  ];

  const handleCheckboxChange = (itemId, propertyName) => {
    console.log('here')
    const updatedData = data.map((section) => {
      const updatedSubSections = section.sub?.map((subSection) => {
        const updatedItems = subSection.data?.map((item) => {
          if (item.id === itemId) {
            const updatedItem = { ...item, [propertyName]: !item[propertyName] };
            columns.forEach((field) => {
              if (field !== propertyName) {
                updatedItem[field] = false;
              }
            });
            return updatedItem;
          }
          return item;
        });
        return { ...subSection, data: updatedItems };
      });
      return { ...section, sub: updatedSubSections };
    });

    // Update the state with the new data
    setData(updatedData);
    sessionStorage.setItem("steptwoFormData", JSON.stringify(updatedData));
  };

  return (
    <>
      <form className="p-4 pt-2">
        <div>
          <div className="bg-light rounded-3 d-inline-block mb-4 p-3">
            <label className="">
              <strong>Type of Construction :</strong>{" "}
            </label>{" "}
            <input type="text" disabled value={selectedConstruction} />
          </div>
        </div>
        {data.map((selected) => 
          <React.Fragment key={selected.section}>
              <h4 className="multipleForm-heading bg-primaryLight p-2">
                {selected?.section}
              </h4>
              {selected?.sub?.map((obj) => 
                <React.Fragment key={obj.name}>
                    <h5 className="multipleForm-subHeading ">{obj.name}</h5>

                    <table className="table table-hover multipleForm-table">
                      <thead>
                        <tr>
                          <th
                            className="bg-light"
                            style={{ width: "8%" }}
                            scope="col"
                          ></th>
                          <th
                            className="bg-light"
                            style={{ width: "40%" }}
                            scope="col"
                          >
                            BCA CLAUSE
                          </th>
                          {columns.map((col, i) => <th
                            key={i}
                            className="bg-light"
                            style={{ width: "10%" }}
                            scope="col"
                          >
                            {col}
                          </th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {obj.data.map((tr) =>
                              tr.id && tr.bcaClause ? (
                                <tr key={tr.id}>
                                  <th scope="row">{tr.id}</th>
                                  <td>{tr.bcaClause}</td>
                                    {columns.map((col, i) => <td key={i}>
                                    <span className="" style={{ display: 'inline-block', width:'22px', lineHeight: '16px', padding: '0.25rem' }}></span>
                                    <label style={{ display: 'inline-block',   margin: 0, cursor: 'pointer', padding: '0.25rem' }}>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={tr[col]}
                                        onChange={() =>
                                          handleCheckboxChange(tr.id, col)
                                        }
                                      />
                                    </label>
                                    {tr[col] && col !== "NOT APPLICABLE" ?
                                      <span className="btn   btn-sm" style={{ display: 'inline-block', width:'22px', lineHeight: '20px', padding: '0' }} onClick={handleShow(col, tr)}>📝</span>
                                      : <span className="" style={{ display: 'inline-block', width:'22px', lineHeight: '16px', }}></span>
                                    }
                                  </td>)}
                                </tr>
                              ) : (
                                ""
                            )
                        )}
                      </tbody>
                    </table>
                </React.Fragment>
                
              )}
          </React.Fragment>
        )}
        <Modal show={showNotes} onHide={handleClose}>
          {noteEdit && <>
          <Modal.Header closeButton>
            <Modal.Title>Edit {noteEdit.column} for {noteEdit.item.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea 
              style={{width: '100%'}}
              value={noteEdit.text} 
              rows={5}
              onChange={e => setNoteEdit({...noteEdit, text: e.target.value})}
            ></textarea>
            </Modal.Body>
          </>}
          
          <Modal.Footer>
          <button className="btn bg-primary ms-1" onClick={handleSave}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </form>
      
      </>
  );
})

export default FormTwo;
