import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminLoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { email, password } = formData;
  
    const LOGIN_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/login`;
  
    const payload = {
      email: email,
      password: password,
    };
  
    try {
      const response = await axios.post(LOGIN_URL, payload);
      if (response.status === 200) {

      sessionStorage.setItem("admin", response.data.data._id);

        toast.success("Logged in successfully",{
          autoClose:1000
        })
        navigate('/admin')

      } else if (response.status === 400) {
        toast.error("gigigi");
      }
    } catch (error) {
      if(error.response.status){
        toast.error("Invalid email or password")
      }
  
    }
  };
  

  return (
    <>
      <div className='position-relative'>
        <div className="loginBG position-absolute aa" style={{background: 'url(../../adminBg.jpg) center center/cover', opacity: '0.5'}}></div>
    
        <div className="container d-flex align-items-center justify-content-center vh-100">
          <div className="card  loginBox border bg-primary rounded-3 box-shadow">
            <h3 className="text-center">Admin Login</h3>
            <form onSubmit={handleSubmit} className=''>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn mt-3 px-4 text-primary fw-bold bg-black">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLoginForm;
