import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import './index.css'
const Sidebar = () => 
{
  
    return (
      <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial',position:"fixed" }}>
        <CDBSidebar textColor="#fff" backgroundColor="#333">
          <CDBSidebarHeader >
            <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
              Step 1
            </a>
          </CDBSidebarHeader>

          <CDBSidebarHeader >
            <a href="/documents" className="text-decoration-none" style={{ color: 'inherit' }}>
              Documents
            </a>
          </CDBSidebarHeader>
        </CDBSidebar>
      </div>
    );
  };
  
  export default Sidebar;