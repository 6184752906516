import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

function UploadForm() {
  const [files, setFiles] = useState({
    "bca_template": {
      name: "bca_template",
      description: "BCA Template",
      format: ".docx",
      file: null
    },
    "bca_section2": {
      name: "bca_section2",
      description: "BCA Section 2 Config",
      format: ".json",
      file: null
    },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [jsonEdit, setJsonEdit] = useState({ text: "", fileName: "" });

  const UPLOAD_FILE = `${process.env.REACT_APP_BACKEND_URL}/admin/files`;
  const DOWNLOAD_DOC = `${process.env.REACT_APP_BACKEND_URL}/admin/files`;

  const handleFileChange = (name) => (e) => {
    var match = files[name];
    setFiles({ ...files, [name]: { ...match, file: e.target.files[0] } });
  };

  const handleUpload = (config) => async () => {
    if (!config.file) {
      alert("Please choose a file to upload.");
      return;
    }

    if (config.file.type === "application/json") {
      try {
        const fileContent = await config.file.text();
        JSON.parse(fileContent);
      } catch (error) {
        alert("The JSON file is invalid. Please upload a valid JSON file.");
        return;
      }
    }

    const formData = new FormData();
    formData.append(config.name, config.file);

    try {
      const response = await axios.post(UPLOAD_FILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Server Response:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadDocument = async (document_name, document_format) => {
    try {
      setIsLoading({ ...isLoading, [document_name]: true });

      const response = await axios.get(`${DOWNLOAD_DOC}/${document_name}${document_format}`, {
        responseType: "blob",
      });

      if (response.status === 404) {
        setErrorMessage("Document not found");
        return;
      }

      const blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = document_name;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      setIsLoading({ ...isLoading, [document_name]: false });
    }
  };

  const editJsonDocument = async (document_name) => {
    try {
      setIsLoading({ ...isLoading, [document_name]: true });

      const response = await axios.get(`${DOWNLOAD_DOC}/${document_name}.json`, {
        responseType: "blob",
      });

      const text = await response.data.text();
      setJsonEdit({ text, fileName: document_name });
      setShowModal(true);
    } catch (error) {
      console.error("Error editing document:", error);
    } finally {
      setIsLoading({ ...isLoading, [document_name]: false });
    }
  };

  const handleSaveJson = async () => {
    try {
      JSON.parse(jsonEdit.text); // Validate JSON
      const blob = new Blob([jsonEdit.text], { type: "application/json" });
      const file = new File([blob], `${jsonEdit.fileName}.json`, { type: "application/json" });

      const formData = new FormData();
      formData.append(jsonEdit.fileName, file);

      await axios.post(UPLOAD_FILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("File saved successfully!");
      setShowModal(false);
    } catch (error) {
      alert("Invalid JSON. Please correct it.");
    }
  };

  return (
    <div className="container">
      {Object.entries(files).map(([key, subject]) => (
        <div className="row mt-3" key={subject.name}>
          <div className="col-lg-6">
            <div className="Admin-uploadUserBox mt-2">
              <h6>{subject.description}:</h6>
              <div className="d-flex gap-2">
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileChange(subject.name)}
                  accept={subject.format}
                />
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => downloadDocument(subject.name, subject.format)}
                >
                  Download
                </button>
              </div>
              <div className="mt-2 d-flex gap-2">
                <button className="btn btn-primary btn-sm" onClick={handleUpload(subject)}>
                  Upload
                </button>
                {subject.format === ".json" && (
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => editJsonDocument(subject.name)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit JSON: {jsonEdit.fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            style={{ width: "100%", height: "65vh" }}
            value={jsonEdit.text}
            onChange={(e) => setJsonEdit({ ...jsonEdit, text: e.target.value })}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn bg-primary" onClick={handleSaveJson}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UploadForm;
