import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPasswordForm = () => {
  const [token, setToken] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [expirationTime, setExpirationTime] = useState("");
  const [clientId, setClientId] = useState("");
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  const CHANGE_PASSWORD = `${process.env.REACT_APP_BACKEND_URL}/user/change-password`;
  const [formData, setFormData] = useState({
    clientId: "",
    password: "",
    confirmPassword: "",
  });

  // const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirmPassword, clientId } = formData;

    if (password !== confirmPassword) {
      toast.warn("Passwords do not match.");
      return;
    }

    try {
      setLoading(true)
      // Replace with your API endpoint
      const response = await axios.post(CHANGE_PASSWORD, {
        clientId,
        password,
        confirmPassword,
      });

      if (response.status === 200) {
        setLoading(false)
        toast.success("Password reset successfully.");
        navigate("/");
      }
    } catch (error) {
      setLoading(false)
      toast.error("Error resetting password.");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = searchParams.get("token");
    const expirationTimeFromUrl = searchParams.get("expirationTime");

    setFormData({ ...formData, clientId: tokenFromUrl });
    console.log(token, expirationTimeFromUrl);

    setToken(tokenFromUrl);
    setExpirationTime(expirationTimeFromUrl);

    // if (tokenFromUrl && expirationTimeFromUrl) {
    //   const expirationDat = new Date(expirationTimeFromUrl);
    //  const expirationDate = expirationDat.toISOString()
    //   const currentDate = new Date();

    //   console.log(currentDate,expirationDate)
    //   if (currentDate < expirationTimeFromUrl) {
    //     setIsTokenValid(true);
    //     console.log("idnddnd")
    //   }
    // }
  }, []);

  return (
    <div className="position-relative d-flex justify-content-center flex-column align-items-center vh-100">
      <div
        className="loginBG position-absolute "
        style={{
          background:
            "linear-gradient( to top ,rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)) , url(../../lognGenBG.jpg) center center/cover",
          backgroundSize: "cover",
        }}
      ></div>

      <div className="" style={{ minHeight: "20vh" }}>
        <h1
          className=""
          style={{
            textAlign: "center",
            fontSize: "90px",
            fontWeight: "bold",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
          }}
        >
          The Generator
        </h1>
      </div>
      <div className="container d-flex justify-content-center align-items-center ">
        <div className="loginBox border bg-primary rounded-3 box-shadow">
          <form onSubmit={handleSubmit}>
            <h2>Reset Password</h2>
            <hr></hr>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" disabled={loading}  className="btn mt-2 px-4 text-primary fw-bold bg-black">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
