import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const LoginForm = ({}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading,setLoading]=useState(false)

  const LOGIN_URL = `${process.env.REACT_APP_BACKEND_URL}/user/login`;

  const handleLogin = async (e) => {
    e.preventDefault();

    const payload = {
      email: email,
      password: password,
      deviceToken: "sampleDeviceToken",
    };




    try {
      setLoading(true)
      const response = await axios.post(LOGIN_URL, payload);

      if (response.status === 200) {
        setLoading(false)
        sessionStorage.setItem("clientId", response.data.data.userId);
        sessionStorage.setItem("JWT", response.data.data.accessToken);
        window.location.href = "/";
      }
    } catch (error) {
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  return (
    <div className="position-relative d-flex justify-content-center flex-column align-items-center vh-100">
      <div
        className="loginBG position-absolute "
        style={{
          background:
            "linear-gradient( to top ,rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)) , url(../../lognGenBG.jpg) center center/cover",
          backgroundSize: "cover",
        }}
      ></div>

      <div className="" style={{ minHeight: "20vh" }}>
        <h1
          className=""
          style={{
            textAlign: "center",
            fontSize: "90px",
            fontWeight: "bold",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
          }}
        >
          The Generator
        </h1>
      </div>
      <div className="container d-flex justify-content-center align-items-center ">
        <div className="loginBox border bg-primary rounded-3 box-shadow">
          <form onSubmit={handleLogin}>
            <h2>Login</h2>
            <hr></hr>
            <div className="mb-1">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => {
                  // Local variable to remove $ for string inputs
                  let sanatisedInput;
                  // Local variable to store input for string conversion
                  let stringConvert;
                  sanatisedInput = e.target.value.replace(/\$/g, "");
                  stringConvert = sanatisedInput.toString();
                  //console.log(typeof e.target.value);
                  setEmail(stringConvert)}
              }
                  
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => {
                  let sanatisedInput;
                  let stringConvert;
                  sanatisedInput = e.target.value.replace(/\$/g, "");
                  stringConvert = sanatisedInput.toString();
                  //console.log(typeof e.target.value);
                  setPassword(stringConvert)}
                }
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn mt-2 px-4 text-primary fw-bold bg-black"
            >
              Login
            </button>
            <span className="text-black ms-5">
              <Link to="/forgotpassword">Forgot password ?</Link>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
