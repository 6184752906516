import { memo } from "react";
import DocumentList from "../components/DocumentList";
import { Link } from "react-router-dom";
const RenderStep3 = () => {
  const clientId = sessionStorage.getItem("clientId");

  return (
    <div className="content">
      <div className="container multipleForm">
        <div className="row justify-content-center">
          <div className="col">
            <div className="bg-white pb-4 shadow-sm m-lg-5 my-4 border overflow-hidden rounded-3">
              <div>
                <h3 className="bg-primary p-2 ">Step 3: Documents</h3>
                    <DocumentList userId={clientId} />
              </div>
              <div className="mt-3 px-3">
                <Link to="/step2">
                  <button className="btn border">Previous</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(RenderStep3);
