import React, { useEffect, useState } from "react";
import LoginForm from "./form/LoginForm";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AdminPanel from "./components/AdminPanel";
import AdminLoginForm from "./form/adminLogin";
import DocumentList from "./components/DocumentList";
import './form/index.css'
import Sidebar from "./form/SideBar";
import SideMenu from "./components/SideMenu";
import TopNavbar from "./components/Navbar";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserListComponent from "./components/UserList";
import User from "./Routes/User/User";
import Admin from "./Routes/Admin/Admin";

const App = () => {
  const clientId = sessionStorage.getItem('clientId')
  const admin = sessionStorage.getItem('admin')
  const [isLoggedIn, setIsLoggedIn] = useState(clientId);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(admin);



  if (process.env.NODE_ENV === 'production') {
    console.log = function() {};
  }




  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.removeItem("formData");
      sessionStorage.removeItem("selectedConstruction");
      sessionStorage.removeItem("selectionType");
      sessionStorage.removeItem("classification");
      sessionStorage.removeItem("buildingContain")
    }
  }, [isLoggedIn]);


  function DocumentListWrapper({ userId }) {
    return (
      <>
      <div className="container">
      <DocumentList userId={clientId}/>
      </div>
    </>
    );
  }

  return (
    <Router>
      <div>
        <ToastContainer />
        <Routes>
          <Route
            path="/*"
            element={<User />}
          />
          <Route
          path="/admin/*"
          element={<Admin />}
        />
        </Routes>

      </div>
    </Router>
  );
};

export default App;
