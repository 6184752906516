import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function UserListComponent( {setCurrentUserID}) {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');


  const GETUSERS_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/users`;
  const DELETE_USER_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/users`;

 
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };

   
    const fetchUsers = async () => {
      try {
        const response = await axios.get(GETUSERS_URL,{
        params: {
          skip:currentPage,
          searchKey : searchKey
        }
        });
        setUsers(response.data.users);

      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`${DELETE_USER_URL}/${userId}`);
      toast.success("User deleted successfully");
      fetchUsers();
    } catch (error) {
      toast.error("Error deleting user");
      console.error("Error deleting user:", error);
    }
  };

  const handleGetDocuments = async (userId) =>{
    setCurrentUserID(userId)


    navigate(`/admin/user/${userId}`);

  }

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <div className="container@ userList-page">
     
      <h2>List of Users</h2>
      <div className="input-group mb-3 mt-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <div style={{ width: '30%' }}>
          <input
            type="text"
            placeholder="Search user..."
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            className="form-control"
          />
        </div>
        <button className="btn bg-primary" onClick={fetchUsers}>Search</button>
        
      </div>
      <ul className="list-group userList step3-documentList ">
        <li className="tableHead bg-primary  p-2">
          <div className="d-flex justify-content-between align-items-start">
                <div className="col-1"><strong>Sr. No</strong></div>
                <div  className="col-4"><strong>Name </strong></div>
                <div  className="col-3"><strong>Email </strong></div>
                <div  className="col-4 text-end"><strong className="me-4">Action</strong></div>
            </div>
        </li>
        {users.map((user,index) => (
          <>
            
            <li key={user._id} className="list-group-item">
              <div className="d-flex justify-content-between align-items-start">
                <div className="col-1">{index+1+(currentPage*10-10)}.</div>
                <div  className="col-4">{user.firstName}</div>
                <div  className="col-3">{user.email}</div>
                <div  className="col-4 text-end">
                  <button
                    className="btn btn-sm btn-danger me-4 "
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-sm  me-5@ text-white bg-black "
                    onClick={() => handleGetDocuments(user._id)}
                  >
                    Documents
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </li>
          </>
        ))}
      </ul>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <button
          className="btn bg-primary me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1} // Disable "Previous" button on the first page
        >
          Previous
        </button>
        <p className='m-2'>Page :{currentPage}</p>
        { users.length === 10  && (
        <button className="btn bg-primary" onClick={handleNextPage}>
          Next
        </button>
        ) 
        }
      </div>
    </div>
  );
}

export default UserListComponent;
