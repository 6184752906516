// DocumentList.js
import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function DocumentList({ userId, setdocumentCount }) {
  const [documents, setDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedDocumentId, setSelectedDocumentId] = useState([]); // Array for multiple selections
  const [message, setMessage] = useState(null); // New state for messages
  const [isDeleting, setIsDeleting] = useState(false);

  const GET_DOC_URL = `${process.env.REACT_APP_BACKEND_URL}/generate-doc/getAllDoc`;
  const DOWNLOAD_DOC = `${process.env.REACT_APP_BACKEND_URL}/generate-doc/download-doc`;
  const DELETE_DOC = `${process.env.REACT_APP_BACKEND_URL}/generate-doc/delete`;

  const downloadDocument = async (document_name,document_client,jobNumber) => {
    try {
      // setIsLoading(true);
      setIsLoading({ ...isLoading, [document_name]: true });

      const response = await axios.get(
        `${DOWNLOAD_DOC}/${document_client}/${document_name}`,
        {
          responseType: "blob",
        }
      );

      if (response.status === 404) {
        setErrorMessage("Document not found");
        return;
      }

      const blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = jobNumber ?? document_name;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      // setIsLoading(false);
      setIsLoading({ ...isLoading, [document_name]: false });
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(GET_DOC_URL, {
        params: {
          clientId: userId,
          searchKey: searchKey,
          fromDate: fromDate,
          toDate: toDate,
          skip: currentPage,
        },
      });
      setDocuments(response.data.documents);

      const totalpages = Math.ceil(response.data.count / 10);
      setTotalCount(totalpages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const refecthDocuments = async () => {
    try {
      const response = await axios.get(GET_DOC_URL, {
        params: {
          clientId: userId,
          searchKey: '',
          fromDate: '',
          toDate: '',
          skip: 1,
        },
      });
      setDocuments(response.data.documents);

      const totalpages = Math.ceil(response.data.count / 10);
      setTotalCount(totalpages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCheckboxChange = (documentId) => {
    setSelectedDocumentId((prevSelected) =>
      prevSelected.includes(documentId)
        ? prevSelected.filter((id) => id !== documentId) // Deselect if already selected
        : [...prevSelected, documentId] // Select if not already selected
    );
  };

  // Handle bulk deletion
  const handleBulkDelete = async () => {
    if (selectedDocumentId.length === 0) {
      setMessage({ type: 'warning', text: 'No documents selected for deletion.' });
      return;
    }
  
    // **Optional: Confirm deletion with the user**
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedDocumentId.length} document(s)?`
    );
    if (!confirmDelete) return;
  
    setIsDeleting(true);
    setMessage(null); // Reset any previous messages
  
    try {
      const response = await axios.post(DELETE_DOC, {
        ids: selectedDocumentId, // Send the array of _id's
      });
  
      if (response.status === 200) {
        // setMessage({ type: 'success', text: response.data.message });
        toast.success(response.data.message);
        
        // **After successful deletion, refetch documents**
        // Check if the current page might be empty after deletion
        const remainingDocuments = documents.length - selectedDocumentId.length;
        if (remainingDocuments <= 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1); // Move to the previous page
        } else {
          fetchDocuments(); // Refetch the current page
        }
        
        setSelectedDocumentId([]); // Clear the selection
      }
    } catch (error) {
      console.error('Error deleting documents:', error);
      if (error.response) {
        // Server responded with a status other than 2xx
        // setMessage({ type: 'error', text: error.response.data.message });
        toast.error(error.response.data.message);
      } else {
        // Network or other errors
        // setMessage({ type: 'error', text: 'An error occurred while deleting documents.' });
        toast.error('An error occurred while deleting documents.');
      }
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPage]);

  return (
    <div
      className="step3 @container p-3"
      style={{ width: "100%", justifyContent: "center" }}
    >
      <div
        className="input-group mb-3 mt-4"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <div
          className="d-flex justify-content-end me-2"
          style={{ width: "30%" }}
        >
          <input
            type="text"
            placeholder="Search documents..."
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            className="form-control"
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control me-2"
          />
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="form-control"
          />
          <button
            className="btn btn-light ms-1"
            onClick={() => {
              setSearchKey("");
              setFromDate("");
              setToDate("");
              refecthDocuments()
            }}
          >
            Reset
          </button>
          <button className="btn bg-primary ms-1" onClick={fetchDocuments}>
            Search
          </button>
        </div>
      </div>

      {documents.length === 0 ? (
        <p>No documents found.</p>
      ) : (
        <>
          <ul
            className="step3-documentList p-0"
            style={{
              "minHeight": "77vh",
              "maxHeight": "77vh",
              "overflowY": "auto",
            }}
          >
            <li className="tableHead bg-primary  p-2">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="col- text-center@">
                  {/* Delete Button with Icon */}
                  <button
                    className="btn me-auto p-0 pb-1"
                    onClick={handleBulkDelete}
                    disabled={selectedDocumentId.length === 0} // Disable if no documents are selected
                    style={{
                      visibility: selectedDocumentId.length > 0 ? "visible" : "hidden", // Hide if no documents are selected
                      outline: "none", // Remove the outline
                      border: "none",
                      cursor: isDeleting ? 'not-allowed' : 'pointer'
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      stroke="red"
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth="2" 
                      viewBox="0 0 24 24"
                    >
                      <rect
                        width="12"
                        height="14"
                        x="6"
                        y="7"
                        rx="1.5"
                        ry="1.5"
                      />
                      <path d="M5 7h14M9 7V5h6v2M10 11v6M14 11v6" />
                    </svg>

                  </button>

                </div>
                <div className="col-1 ">
                  <strong>Sr. No</strong>
                </div>
                <div className="col-2 text-center@">
                  <strong>Document ID</strong>
                </div>
                <div className="col-3">
                  <strong>Project Name</strong>
                </div>
                <div className="col-3 text-center@">
                  <strong>Created On </strong>
                </div>
                <div className="col-2 text-end">
                  <strong>Document</strong>
                </div>
              </div>
            </li>
            {documents.map((document, i) => (
               <li key={document.docId} className={`list-group-item mb-3 py-2 key-${document.docId} _id-${document._id}`}>
                <div className="d-flex justify-content-between align-items-center">
                <div className="col- ms-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedDocumentId.includes(document._id)}
                      onChange={() => handleCheckboxChange(document._id)}
                    />
                  </div>
                  <div className="col-1">
                    <span className="fw-bold ps-2"></span> {i + 1}
                  </div>
                  <div className="col-2">
                    <span className="fw-bold"></span> {document.docId}
                  </div>
                  <div className="col-3">
                    <strong className="ps-2">{document.projectName} </strong>
                  </div>
                  <div className="col-3">
                    <span className="fw-bold"></span>{" "}
                    {new Date(document.createdAt).toLocaleString()}
                  </div>
                  <div className="col-2 text-end">
                    <button
                      className="btn btn-sm btn-light  "
                      onClick={() => {
                        downloadDocument(document.document_name,document.clientId,document.jobNumber);
                      }}
                    >
                      Download Docx
                      {isLoading[document.document_name] ? (
                        <span
                          className="spinner-border spinner-border-sm ms-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </li>
            ))}
            {errorMessage && (
              <div className="alert alert-warning">{errorMessage}</div>
            )}
            {message && (
              <div
                className={`alert ${
                  message.type === 'success'
                    ? 'alert-success'
                    : message.type === 'error'
                    ? 'alert-danger'
                    : 'alert-warning'
                }`}
                role="alert"
              >
                {message.text}
              </div>
            )}
          </ul>
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <button
          className="btn bg-primary me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1} // Disable "Previous" button on the first page
        >
          Previous
        </button>
        <p className="m-2">Page :{currentPage}</p>
        {currentPage < totalCount && (
          <button className="btn bg-primary" onClick={handleNextPage}>
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export default memo(DocumentList);
