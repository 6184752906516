import React, { useEffect, useState } from 'react';
import UserListComponent from './UserList';
import UserForm from './UserForm';
import axios from 'axios';
import SideMenu from './SideMenu';
import DocumentList from './DocumentList';
import UploadForm from './UploadForm';

function AdminPanel({setIsAdminLoggedIn}) {
  const [selectedFunctionality, setSelectedFunctionality] = useState('users');
  const [userCount, setUserCount] = useState(0);
  const [documentCount, setdocumentCount] = useState(0);


  const handleFunctionalityClick = (functionality) => {
    setSelectedFunctionality(functionality);
  };



  const renderFunctionality = () => {
    switch (selectedFunctionality) {
      case 'users':
        return (
          <UserListComponent setUserCount={setUserCount}
          />
        );
      case 'add User':
        return (
          <UserForm />
        );
      case 'documents':
        return (
          <DocumentList setdocumentCount={setdocumentCount}/>
        );
        case 'upload template':
        return (
          <UploadForm />
        );
      default:
        return (
          <UserListComponent
          />
        );
    }
  };

  return (
    <div className="admin-panel container-fluid h-100 ">
      <div className="row h-100">
        <SideMenu setIsAdminLoggedIn={setIsAdminLoggedIn} selectedFunctionality={selectedFunctionality} handleFunctionalityClick={handleFunctionalityClick} userCount={userCount} documentCount={documentCount} />

        <div className="col-md-10 p-4 overflow-auto"> 
          {renderFunctionality()}
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
